const translate = {
  _SEO_TITLE1: 'Zatrudnij światowej klasy programistów dla swojej firmy',
  _SEO_TITLE2:
    'Świadczymy usługi programistyczne React.js, aby pomóc firmom i start-upom w tworzeniu ich marzeń. Zawsze poszukujemy najlepszych rozwiązań dla biznesów naszych klientów. Jednym z takich rozwiązań jest wykorzystanie React.js do tworzenia usług online i aplikacji internetowych.',
  _SEO_TITLE3:
    'Reaguj tworzenie aplikacji, rozwój Nodejs, reagują aplikacje, renderowanie po stronie serwera, aplikacje SSR, aplikacja SSR reagująca',
  _HOME_LINK1: 'Ekspertyza',
  _HOME_LINK2: 'Jak my pracujemy',
  _HOME_CONTACT: 'Skontaktuj',
  _HOME_TITLE1: 'SZYBKO',
  _HOME_TITLE2: 'WZROST',
  _HOME_TITLE31: 'Tworzenie cyfrowych arcydzieł',
  _HOME_TITLE32:
    'Witamy w świecie programowania Node.js i React.js! Jesteśmy zespołem doświadczonych programistów specjalizujących się w tworzeniu wyjątkowych aplikacji internetowych z wykorzystaniem najnowocześniejszych technologii. Naszym celem jest pomóc Ci wcielić Twoje pomysły w życie, zapewniając jednocześnie najwyższą wydajność i niezawodność Twojego produktu.',

  _HOME_TITLE3: 'Zatrudnij światowej klasy programistów do swojej firmy',
  _HOME_TITLE4:
    'Jesteśmy małym, entuzjastycznym studiem tworzenia aplikacji internetowych i mobilnych z Charkowa na Ukrainie. Tworzymy aplikacje webowe i mobilne dla naszych klientów.',
  _HOME_TEXT1:
    'Niezależnie od tego, czy szukasz MVP, kompleksowej platformy internetowej, czy też zatrudniasz dedykowany zespół programistów, jesteśmy do Twojej dyspozycji. Ciężko pracujemy, aby dostarczać produkty, dzięki czemu możesz mieć pewność, że korzystasz z technologii i skupić się na swojej podstawowej działalności.',
  _HOME_TEXT2:
    'Świadczymy usługi programistyczne React.js, aby pomóc firmom i startupom stworzyć ich wymarzone projekty. Zawsze poszukujemy najlepszych rozwiązań dla biznesów naszych klientów. Jednym z takich rozwiązań jest wykorzystanie React.js do tworzenia usług online i aplikacji internetowych.',

  _HOME_SECTION1a: 'PRZEJRZYSTOŚĆ JEST W Sednie tego, co robimy',
  _HOME_SECTION1b:
    'Nie ma „najlepszych praktyk” dla wszystkich rodzajów projektów. Zawsze analizujemy wymagania klienta i podstawowe informacje na wczesnym etapie poprzedzającym rozwój, aby określić najlepszy proces, który doprowadzi do sukcesu projektu.',
  _HOME_SECTION1c:
    'Ty zarządzasz projektami, a nasi zaufani programiści dołączają do Twojego zespołu na pełny etat i rozwiązują Twoje problemy oraz budują funkcje dla Twojego produktu.',
  _HOME_SECTION1d:
    'Nie sprzedajemy godzin - rozwiązujemy Twoje cele i problemy!',
  _HOME_SECTION1e: 'Nasz stos React dla aplikacji',

  _HOME_SECTION2a: 'Oferta specjalna',
  _HOME_SECTION2b:
    'Jeśli chcesz przepisać/zrefaktoryzować swoją obecną aplikację/stronę - możemy przeprowadzić BEZPŁATNY audyt i wycenę z sugestiami - jak ulepszyć Twoją aplikację (stronę) i nie wydać za dużo pieniędzy i czasu!',
  _HOME_SECTION2c:
    'W przypadku, gdy chcesz stworzyć nową aplikację/stronę, możemy przeprowadzić etap rozwoju z prostym MVP (na podstawie Twoich makiet) w JEDNYM TYGODNIU!',
  _HOME_SECTION2d: 'Stos propozycji:',
  _HOME_SECTION2e:
    'MVP (minimalny produkt) na JEDEN TYDZIEŃ (gotowość do CI/CD)',

  _HOME_SECTION3: 'Nasza ekspertyza',
  _HOME_SECTION4:
    'Jeśli chcesz przepisać/zrefaktoryzować swoją obecną aplikację/stronę - możemy przeprowadzić BEZPŁATNY audyt i wycenę z sugestiami - jak ulepszyć Twoją aplikację (stronę) i nie wydaliśmy zbyt dużo czasu i pieniędzy!',
  _HOME_SECTION5: 'Pokaż więcej',

  _HOME_SECTION6: 'CECHY PRODUKTU',
  _HOME_SECTION7:
    'React, pierwotnie opracowany dla Facebooka, to biblioteka JavaScript, która tworzy interfejsy użytkownika dla aplikacji jednostronicowych, dzieląc interfejs użytkownika na możliwe do komponowania komponenty. Ponieważ wymaga jedynie minimalnej znajomości HTML i JavaScript, React zyskał na popularności jako narzędzie do tworzenia front-endowych stron internetowych.',
  _HOME_SECTION8:
    'W przypadku, gdy chcesz stworzyć nową aplikację/stronę, możemy przeprowadzić etap rozwoju z prostym MVP (na podstawie Twoich makiet) w JEDNYM TYGODNIU!',
  _HOME_SECTION9:
    'ReactJS jest zdecydowanie gotowy do produkcji. Dzięki temu pisanie JavaScriptu staje się łatwiejsze.',
  // -----------

  EXT_T1: 'NASZA EKSPERTYZA',
  EXT_TT:
    'Nasz zespół może zapewnić szeroką wiedzę specjalistyczną w zakresie technologii oprogramowania mobilnego, internetowego, stacjonarnego i korporacyjnego we wszystkich głównych środowiskach programistycznych.',

  WANT_BUTTON: 'Chcieć wiedzieć więcej?',
  EXP_TITLE1: 'Rozwiązania z zakresu elektromobilności',
  EXP_TEXT1:
    'Efektywne rozwiązania dla obu stron branży e-mobility – operatorów EMSP i CPO. Gwarantowane bezproblemowe doświadczenie dzięki wdrażaniu otwartych standardów, tworzeniu aplikacji i platform, doradztwu w zakresie domen i wielu innym.',

  EXP_TITLE2: 'Dowolny typ aplikacji React',
  EXP_TEXT2:
    'Wyobraź sobie, że musisz od podstaw stworzyć aplikację internetową, która będzie monitorować i analizować surowe dane. Na przykład jesteś właścicielem średniej wielkości sklepu internetowego. Nie zadowalają Cię dotychczasowe rozwiązania do analityki danych i chcesz samodzielnie zarządzać, jakie komponenty znajdą się w Twoim panelu administracyjnym. Bardzo ważna jest także Twoja praca z surowymi danymi i samodzielne budowanie wykresów analitycznych.',

  EXP_TITLE3: 'Przepływy danych dla projektów IOT',
  EXP_TEXT3:
    'Internet rzeczy, czyli IoT, to system wzajemnie powiązanych urządzeń obliczeniowych, maszyn mechanicznych i cyfrowych, obiektów, zwierząt lub osób, które posiadają unikalne identyfikatory (UID) i możliwość przesyłania danych w sieci bez konieczności kontaktu człowieka z człowiekiem. interakcji człowiek lub człowiek-komputer.',
  EXP_TEXT4:
    'Rzeczą w Internecie rzeczy może być osoba z implantem monitorującym pracę serca, zwierzę hodowlane z transponderem biochipu, samochód z wbudowanymi czujnikami ostrzegającymi kierowcę o niskim ciśnieniu w oponach lub jakikolwiek inny naturalny lub sztuczny przypadek obiekt, któremu można przypisać adres protokołu internetowego (IP) i który może przesyłać dane w sieci.',
  EXP_TEXT5:
    'Coraz częściej organizacje z różnych branż korzystają z Internetu Rzeczy, aby działać wydajniej, lepiej rozumieć klientów, co pozwala im zapewniać lepszą obsługę, usprawniać proces podejmowania decyzji i zwiększać wartość firmy.',
  EXP_TITLE6: 'Obraz na żywo',
  EXP_TEXT6:
    'Oferujemy szereg usług tworzenia aplikacji do strumieniowego przesyłania wideo, a duży zespół specjalistów jest dostępny, aby zapewnić wyniki. W zależności od Twoich potrzeb możemy zbudować szybko działające platformy do transmisji na żywo lub usługi wideo na żądanie. Aplikacje te są nie tylko stworzone z myślą o maksymalnej wygodzie (zarówno pod względem komfortu użytkowania, jak i zarządzania administracyjnego), ale są również niezawodne i doskonale radzą sobie z obciążeniem związanym z dużym przepływem ruchu.',

  _HWORK1: 'Jak pracujemy',
  _HWORK2:
    'Nie ma pomysłu tak złożonego, aby nie można było opracować optymalnego rozwiązania architektonicznego. Nasz zespół doświadczonych inżynierów React wniesie do Twojego projektu bogatą wiedzę instytucjonalną na temat React, opartą na naszym rzeczywistym doświadczeniu w tworzeniu różnorodnych aplikacji, od MVP po złożone aplikacje klasy korporacyjnej.',
  _HWORK3: 'Zapewniamy',
  _HWORK31: 'najnowocześniejszy stos technologii dla Front-endu i Backendu',
  _HWORK32:
    'Dobre praktyki oparte na naszym doświadczeniu z wieloma realnymi projektami produkcyjnymi',
  _HWORK34: 'Rozwiązania gotowe do produkcji i CI/CD',
  _HWORK35: 'wykwalifikowani architekci React Native, React i Backend',
  _HWORK36: 'profesjonalna opinia na temat istniejącego projektu',
  _HWORK37: 'wsparcie realizacji projektu od początku do końca',
  _HWORK4: 'Skoncentruj się na testowaniu',
  _HWORK5:
    'Kluczową cechą Node.js jest wysoka jakość kodu. Co z kolei oznacza, że jest stabilny, przewidywalny i realizuje swój cel. Ponieważ Node.js może być językiem dynamicznie typowanym i mocnym językiem typowanym, jakość utrzymywała na wymaganym poziomie poprzez wykonywanie automatycznych testów. Ekosystem Node.js zapewnia kilka najnowocześniejszych platform do automatycznego testowania. Od lat są dopracowywane i testowane w boju przez najwybitniejszych programistów w społeczności',
  _HWORK6: 'Chcesz poznać więcej przykładów?'
};

export default translate;
