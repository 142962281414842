import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  number: Yup.string()
    .matches(/(^[0-9 ]+$)/, 'Please, fill full card number')
    .required('Please, provide card number')
    .min(16, 'Please, fill full card number'),

  name: Yup.string()
    .matches(/(^[A-z ]+$)/, 'Please, fill only latin chars')
    .min(10, 'Name should be at least 10 symbols')
    .required('Please, enter cardholder name'),

  expire: Yup.string()
    .matches(
      /(^(0[1-9]|1[0-2])\/?([1|2])([0-9]{1})$)/,
      'Please, fill expiry date'
    )
    .required('Please, enter expiry date'),

  cvc: Yup.string()
    .matches(/(^[0-9]+$)/, 'Please, fill CVC')
    .min(3, 'CVC should be at least 3 symbols')
    .required('Please, provide CVC')
});

export default validationSchema;
