import React from 'react';

import Section from '../Section';
import styles from './buy.module.scss';
import userIcon from '../../assets/svg/user.svg';

const StationListScreen = () => (
  <Section className={styles.section}>
    <div className={styles.top1}>
      <div className={styles.context}>
        <p className={styles.first}>Welcome, USERNAME</p>
        <div className={styles.photo}>
          <img src={userIcon} alt="User" />
        </div>
        <p className={styles.number}>xxx</p>
      </div>
    </div>

    <div className={styles.podlojka}>
      <p className={styles.title}>Assets</p>

      <div className={styles.conteiner}>
        <div className={styles.circle}></div>
        <p className={styles.text1}>Remainder</p>
        <p className={styles.title1}> xxx </p>
        <p className={styles.price1}>xxx</p>
        <p className={styles.price2}>xxx</p>

        <p></p>

        <div className={styles.circle}></div>
        <p className={styles.text1}>Compaund</p>
        <p className={styles.title1}> xxx </p>
        <p className={styles.price1}>xxx</p>
        <p className={styles.price2}>xxx</p>

        <p></p>

        <div className={styles.circle}></div>
        <p className={styles.text1}>Radium</p>
        <p className={styles.title1}> xxx </p>
        <p className={styles.price1}>xxx</p>
        <p className={styles.price2}>xxx</p>
      </div>

      <p className={styles.see}>See all assets</p>
      <p className={styles.recom}>Recomended to Buy</p>

      <ul className={styles.bottomList}>
        <li>
          <div className={styles.circle1} />
          <p className={styles.textinbop}>Ripple</p>
          <p className={styles.textinbop2}>xxx</p>
          <p className={styles.textinbop3}>xxx</p>
        </li>

        <li>
          <div className={styles.circle1} />
          <p className={styles.textinbop}>Avalanche</p>
          <p className={styles.textinbop2}>xxx</p>
          <p className={styles.textinbop3}>xxx</p>
        </li>

        <li>
          <div className={styles.circle1} />
          <p className={styles.textinbop}>Solana</p>
          <p className={styles.textinbop2}>xxx</p>
          <p className={styles.textinbop3}>xxx</p>
        </li>
      </ul>
    </div>
  </Section>
);

export default StationListScreen;
