import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import * as Scroll from 'react-scroll';
import { useSelector } from 'react-redux';

import Button from '../../components/Button';
import Section from '../../components/Section';
import styles from './styles.module.scss';

import iotImage from '../../assets/img/expertise/iot-ecosystem.webp';
import dashboardImage from '../../assets/img/expertise/react-dashboard.png';
import streamingImage from '../../assets/img/expertise/streaming.jpg';
import mobilityImage from '../../assets/img/expertise/green-energy.jpg';

import metaValues from '../../../config';
import translation from '../../translate';

const scroll = Scroll.animateScroll;

const Dashboards = () => {
  const { language } = useSelector(({ common }: any) => common);
  const link = `/${language}/expertise/react-dashboards`;

  return (
    <div className={styles.grid}>
      <h2>{translation('EXP_TITLE2', language)}</h2>
      <img
        className={styles.image}
        src={dashboardImage}
        alt="React dashboard example"
      />
      <p>{translation('EXP_TEXT2', language)}</p>
      <Link
        to={link}
        onClick={() => scroll.scrollToTop({ duration: 100 })}
      >
        <Button>{translation('WANT_BUTTON', language)}</Button>
      </Link>
    </div>
  );
};

const EMobility = () => {
  const { language } = useSelector(({ common }: any) => common);
  const link = `/${language}/expertise/emobility`;

  return (
    <div className={styles.grid}>
      <h2>{translation('EXP_TITLE1', language)}</h2>
      <img
        className={styles.image}
        src={mobilityImage}
        alt="E-Mobilitys solutions"
      />
      <p>{translation('EXP_TEXT1', language)}</p>
      <Link
        to={link}
        onClick={() => scroll.scrollToTop({ duration: 100 })}
      >
        <Button>{translation('WANT_BUTTON', language)}</Button>
      </Link>
    </div>
  );
};

const IOT = () => {
  const { language } = useSelector(({ common }: any) => common);

  return (
    <div className={styles.grid}>
      <h2>{translation('EXP_TITLE3', language)}</h2>
      <img src={iotImage} className={styles.image} alt="IOT" />
      <p>{translation('EXP_TEXT3', language)}</p>
      <p>{translation('EXP_TEXT4', language)}</p>
      <p>{translation('EXP_TEXT5', language)}</p>
      <Button>{translation('WANT_BUTTON', language)}</Button>
    </div>
  );
};

const Streaming = () => {
  const { language } = useSelector(({ common }: any) => common);

  return (
    <div className={styles.grid}>
      <h2>{translation('EXP_TITLE6', language)}</h2>
      <img
        src={streamingImage}
        className={styles.image}
        alt="Streaming video projects"
      />
      <p>{translation('EXP_TEXT6', language)}</p>
      <Button>{translation('WANT_BUTTON', language)}</Button>
    </div>
  );
};

const Expertise = () => {
  const { defaultTitle } = metaValues;
  const { language } = useSelector(({ common }: any) => common);

  return (
    <div>
      <Helmet>
        <title>
          {translation('_HOME_SECTION3', language)} - {defaultTitle}
        </title>
        <meta
          name="description"
          content="There you can find our expertise description"
        />
        <meta
          property="keywords"
          content="react app development expertise, react tech stack, react dashboards, nodejs occpp, nodejs React API development, nodejs development, react ocpp demo"
        />
        <meta property="og:title" content={defaultTitle} />
        <meta property="og:image" content="/assets/svg/monitor-window.svg" />
      </Helmet>

      <div className={styles.background}>
        <div className={styles.content}>
          <div className={styles.title}>
            <h1>{translation('EXT_T1', language)}</h1>
            <p>{translation('EXT_TT', language)}</p>
          </div>
        </div>
      </div>

      <Section className={styles.content}>
        <ul>
          <li>{EMobility()}</li>
          <li>{Dashboards()}</li>
          <li>{IOT()}</li>
          <li>{Streaming()}</li>
        </ul>
      </Section>
    </div>
  );
};

export default Expertise;
