import axios from 'axios';
import qs from 'querystring';

// import config from '../config';
// import _ from 'lodash';

const isServer = typeof window === 'undefined';

const endpointUrl = url => {
  const result = `${url}`;
  return result;
};

const addAuthHeaders = (options = {}) => {
  const token =
    !isServer && localStorage ? localStorage.getItem('userJWT') : null;

  if (!token) {
    return {};
  }

  return {
    ...options,
    headers: {
      ...options.headers,
      Authorization: `Bearer ${token}`,
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers':
        'Origin, X-Requested-With, Content-Type, Accept'
    }
  };
};

const loadWithParams = (url, values) => {
  const params = qs.stringify(values);
  return axios
    .get(endpointUrl(`${url}?${params}`), addAuthHeaders())
    .then(response => response.data);
};

export default {
  apiURL: endpointUrl,
  home: {
    load: values => loadWithParams('/home', values)
  },
  auth: {
    signup(formData) {
      return axios
        .post(endpointUrl('/auth/signup'), formData)
        .then(response => response.data);
    },

    login(credentials) {
      return axios
        .post(endpointUrl('/auth/signin'), credentials)
        .then(response => response.data);
    },

    setUserBookmark: values =>
      axios
        .post(endpointUrl('/user/bookmarks'), values, addAuthHeaders())
        .then(response => response.data),

    googleLogin(params) {
      return axios
        .post(endpointUrl('/auth/google'), params)
        .then(response => response.data);
    },

    facebookLogin(params) {
      return axios
        .post(endpointUrl('/auth/facebook'), params)
        .then(response => response.data);
    },

    confirmSignup(code) {
      return axios
        .get(endpointUrl(`/auth/signup/confirm/${code}`))
        .then(response => response.data);
    },

    forgotPassword(formData) {
      return axios
        .post(endpointUrl('/auth/password/forgot'), formData)
        .then(response => response.data);
    },

    activation(code) {
      return axios
        .get(endpointUrl(`/auth/activation/${code}`))
        .then(response => response.data);
    },

    resetPassword(formData) {
      return axios
        .post(endpointUrl('/auth/password/reset'), formData)
        .then(response => response.data);
    },

    getEmailByCode(code) {
      return axios
        .post(endpointUrl('/auth/password/code'), { code })
        .then(response => response.data);
    },

    getUserData(params) {
      return axios
        .get(endpointUrl('/account'), addAuthHeaders({ params }))
        .then(response => response.data);
    },

    setUserData(body) {
      return axios
        .put(endpointUrl('/account'), body, addAuthHeaders())
        .then(response => response.data);
    }
  }
};
