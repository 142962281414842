// @flow
import * as React from 'react';

import styles from './footer.module.scss';

const Footer = (): any => {
  const heart = (
    <span role="img" className={styles.emojii} aria-label="Heart">
      ❤️
    </span>
  );
  return (
    <div className={styles.footer}>
      <div className={styles.content}>
        <ul className={styles.grid}>
          <ol>
            <h3>Services</h3>
            <li>E-mobility solutions</li>
            <li>Custom software development</li>
            <li>Dedicated dev teams</li>
            <li>Web Development</li>
            <li>Mobile development</li>
            <li>IT consulting</li>
          </ol>
          <ol>
            <h3>Case Studies</h3>
            <li>True energy</li>
            <li>Ave mobility</li>
            <li>T-Cup</li>
            <li>Grand Vision</li>
            <li>Petroleum Tax Tools</li>
            <li>Concise Software</li>
            <li>Channel Bytes</li>
            <li>Connect your care</li>
          </ol>
          <ol>
            <h3>Technologies</h3>
            <li>Docker</li>
            <li>ReactJS</li>
            <li>React Native</li>
            <li>NodeJS</li>
            <li>Postgres SQL</li>
            <li>MongoDB</li>
            <li>Cloud services</li>
          </ol>
        </ul>
        <div className={styles.title}>
          Made with
          {heart}
          in Kharkiv, Ukraine (с) 2015-2023
        </div>
      </div>
    </div>
  );
};

export default Footer;
