const translate = {
  _SEO_TITLE1: 'Stellen Sie erstklassige Entwickler für Ihr Unternehmen ein',
  _SEO_TITLE2:
    'Wir bieten React.js-Entwicklungsdienste an, um Unternehmen und Startups bei der Umsetzung ihrer Traumprojekte zu unterstützen.“ Wir sind stets auf der Suche nach den besten Lösungen für die Unternehmen unserer Kunden. Eine dieser Lösungen ist die Verwendung von React.js für die Entwicklung von Onlinediensten und Webanwendungen.',
  _SEO_TITLE3:
    'React-Anwendungsentwicklung, Nodejs-Entwicklung, React-Anwendungen, serverseitiges Rendering, SSR-Apps, SSR-React-App',
  _HOME_LINK1: 'Sachverstand',
  _HOME_LINK2: 'Wie wir arbeiten',
  _HOME_CONTACT: 'Kontaktiere uns',
  _HOME_TITLE1: 'SCHNELL',
  _HOME_TITLE2: 'WACHSTUM',

  _HOME_TITLE31: 'Digitale Meisterwerke herstellen',
  _HOME_TITLE32:
    'Willkommen in der Welt der Node.js und React.js Entwicklung! Wir sind ein Team erfahrener Entwickler, die sich auf die Erstellung herausragender Webanwendungen unter Verwendung dieser Spitzentechnologien spezialisiert haben. Unser Ziel ist es, Ihnen dabei zu helfen, Ihre Ideen zum Leben zu erwecken und gleichzeitig die größtmögliche Leistung und Zuverlässigkeit Ihres Produkts sicherzustellen.',

  _HOME_TITLE3: 'Stellen Sie erstklassige Entwickler für Ihr Unternehmen ein',
  _HOME_TITLE4:
    'Wir sind ein kleines, begeistertes Web und Mobile Entwicklungsstudio aus Charkiw, Ukraine. Wir entwickeln Web- und Mobilanwendungen für unsere Kunden.',
  _HOME_TEXT1:
    'Egal, ob Sie ein MVP oder eine umfassende Webplattform entwickeln oder ein engagiertes Entwicklungsteam einstellen möchten, wir sind für Sie da. Wir arbeiten hart daran, Ihnen Ergebnisse zu liefern, damit Sie sich auf die Technologie verlassen und sich auf Ihr Kerngeschäft konzentrieren können.',
  _HOME_TEXT2:
    'Wir bieten React.js-Entwicklungsdienste an, um Unternehmen und Startups bei der Umsetzung ihrer Traumprojekte zu unterstützen. Wir sind stets auf der Suche nach den besten Lösungen für die Unternehmen unserer Kunden. Eine dieser Lösungen ist die Verwendung von React.js für die Entwicklung von Onlinediensten und Webanwendungen.',

  _HOME_SECTION1a: 'TRANSPARENZ STEHT IM KERN UNSERER TÄTIGKEIT',
  _HOME_SECTION1b:
    'Es gibt keine „Best Practice“ für alle Arten von Projekten. Wir analysieren stets die Anforderungen und Primärinformationen des Kunden in der frühen Vorentwicklungsphase, um den besten Prozess zu definieren, der zum Erfolg des Projekts führt.',
  _HOME_SECTION1c:
    'Sie verwalten die Projekte und unsere vertrauenswürdigen Entwickler verstärken Ihr Team in Vollzeit und lösen Ihre Probleme und entwickeln Funktionen für Ihr Produkt.',
  _HOME_SECTION1d:
    'Wir verkaufen keine Stunden – wir lösen Ihre Ziele und Probleme!',
  _HOME_SECTION1e: 'Unser React-Stack für Apps',

  _HOME_SECTION2a: 'Sonderangebot',
  _HOME_SECTION2b:
    'Wenn Sie Ihre aktuelle Anwendung/Site umschreiben/umgestalten möchten, können wir eine KOSTENLOSE Prüfung und Schätzung mit Vorschlägen durchführen, wie Sie Ihre Anwendung (Site) verbessern können, ohne zu viel Geld und Zeit aufzuwenden!',
  _HOME_SECTION2c:
    'In case when you want to create a new application/site we can run developement stage with simple MVP (based on yours mockups) in ONE WEEK!',
  _HOME_SECTION2d: 'Vorschlagsstapel:',
  _HOME_SECTION2e:
    'MVP (minimum viable product) for ONE WEEK (CMVP (Minimum Viable Product) für EINE WOCHE (CI/CD-fähig)',

  _HOME_SECTION3: 'Unsere Kompetenz',
  _HOME_SECTION4:
    'Wenn Sie Ihre aktuelle Anwendung/Site umschreiben/umgestalten möchten, können wir eine KOSTENLOSE Prüfung und Schätzung mit Vorschlägen durchführen – wie Sie Ihre Anwendung (Site) verbessern können, ohne zu viel Geld und Zeit aufzuwenden!',
  _HOME_SECTION5: 'Zeig mir mehr',

  _HOME_SECTION6: 'PRODUKTMERKMALE',
  _HOME_SECTION7:
    'React wurde ursprünglich für Facebook entwickelt und ist eine JavaScript-Bibliothek, die Benutzeroberflächen für Einzelseitenanwendungen erstellt, indem sie die Benutzeroberfläche in zusammensetzbare Komponenten unterteilt.“ Da es nur minimale HTML- und JavaScript-Kenntnisse erfordert, erfreut sich React als Front-End-Webentwicklungstool zunehmender Beliebtheit.',
  _HOME_SECTION8:
    'Wenn Sie eine neue Anwendung/Site erstellen möchten, können wir die Entwicklungsphase mit einfachem MVP (basierend auf Ihren Modellen) in EINER WOCHE durchführen!',
  _HOME_SECTION9:
    'ReactJS ist definitiv produktionsbereit.“ Es erleichtert das Schreiben von Javascript.',
  // -----------

  EXT_T1: 'UNSER ERFAHRUNGSSCHATZ',
  EXT_TT:
    'Unser Team kann ein breites Spektrum an Fachwissen über Mobil-, Web-, Desktop- und Unternehmenssoftwaretechnologie in allen wichtigen Entwicklungsumgebungen bereitstellen.',

  WANT_BUTTON: 'Möchten Sie mehr wissen?',
  EXP_TITLE1: 'E-Mobilitätslösungen',
  EXP_TEXT1:
    'Effiziente Lösungen für beide Seiten der E-Mobilitätsbranche – EMSP- und CPO-Betreiber. Garantierte nahtlose Erfahrung durch Implementierung offener Standards, Entwicklung von Apps und Plattformen, Domänenberatung und vieles mehr.',

  EXP_TITLE2: 'Jede Art von React-Anwendungen',
  EXP_TEXT2:
    'Stellen Sie sich vor, Sie müssen eine Webanwendung von Grund auf entwickeln, um Rohdaten zu überwachen und zu analysieren. Sie besitzen beispielsweise einen durchschnittlich großen Online-Shop. Sie sind mit den bestehenden Lösungen für die Datenanalyse nicht zufrieden und möchten selbst verwalten, welche Komponenten in Ihrem Admin-Panel enthalten sein werden. Es ist auch sehr wichtig, dass Sie mit Rohdaten arbeiten und selbst Analysediagramme erstellen.',

  EXP_TITLE3: 'Datenflüsse für IOT-Projekte',
  EXP_TEXT3:
    'Das Internet der Dinge oder IoT ist ein System miteinander verbundener Computergeräte, mechanischer und digitaler Maschinen, Objekte, Tiere oder Menschen, die mit eindeutigen Identifikatoren (UIDs) und der Fähigkeit ausgestattet sind, Daten über ein Netzwerk zu übertragen, ohne dass eine menschliche Kommunikation erforderlich ist. Mensch- oder Mensch-Computer-Interaktion.',
  EXP_TEXT4:
    'Ein Ding im Internet der Dinge kann eine Person mit einem Herzmonitorimplantat, ein Nutztier mit einem Biochip-Transponder, ein Auto mit eingebauten Sensoren sein, die den Fahrer bei niedrigem Reifendruck warnen, oder irgendein anderes natürliches oder künstliches Ding Objekt, dem eine Internet Protocol (IP)-Adresse zugewiesen werden kann und das in der Lage ist, Daten über ein Netzwerk zu übertragen.',
  EXP_TEXT5:
    'Zunehmend nutzen Unternehmen in verschiedenen Branchen das IoT, um effizienter zu arbeiten, Kunden besser zu verstehen, besseren Kundenservice zu bieten, die Entscheidungsfindung zu verbessern und den Wert des Unternehmens zu steigern.',
  EXP_TITLE6: 'Video streaming',
  EXP_TEXT6:
    'Wir bieten eine Reihe von Entwicklungsdiensten für Video-Streaming-Apps an und verfügen über ein großes Team von Spezialisten, die für die Bereitstellung von Ergebnissen zur Verfügung stehen. Je nach Bedarf können wir schnell laufende Live-Streaming-Plattformen oder Video-on-Demand-Dienste aufbauen. Diese Apps sind nicht nur auf größtmöglichen Komfort ausgelegt (sowohl bei der Benutzererfahrung als auch bei der Administratorverwaltung), sondern sie sind auch robust und bewältigen die Belastung durch große Datenströme fachmännisch.',

  _HWORK1: 'Wie wir arbeiten',
  _HWORK2:
    'Keine Idee ist so komplex, dass nicht die optimale architektonische Lösung entwickelt werden kann.“ Unser Team aus erfahrenen React-Ingenieuren wird Ihrem Projekt umfangreiches institutionelles Wissen über React einbringen, basierend auf unserer praktischen Erfahrung beim Erstellen einer Vielzahl von Anwendungen, von MVP bis hin zu komplexen Apps der Unternehmensklasse.',
  _HWORK3: 'WIR BIETEN',
  _HWORK31: 'Der modernste Technologie-Stack für Front-End und Backend',
  _HWORK32:
    'Best Practices basierend auf unserer Erfahrung mit vielen realen Produktionsprojekten',
  _HWORK34: 'Produktions- und CI/CD-fähige Lösungen',
  _HWORK35: 'Qualifizierte Architekten für React Native, React und Backend',
  _HWORK36: 'Professionelles Feedback zu einem bestehenden Projekt',
  _HWORK37: 'Unterstützung bei der Projektumsetzung von Anfang bis Ende',
  _HWORK4: 'Fokus auf Testen',
  _HWORK5:
    'Das Hauptmerkmal von Node.js ist die hohe Qualität des Codes. Was wiederum bedeutet, dass es stabil und vorhersehbar ist und sein Ziel erreicht. Da Node.js eine dynamisch typisierte Sprache und eine stark typisierte Sprache sein kann, wurde die Qualität durch die Durchführung automatisierter Tests auf dem erforderlichen Niveau gehalten. Das Node.js-Ökosystem bietet mehrere hochmoderne Frameworks für automatisierte Tests. Sie wurden jahrelang von den prominentesten Entwicklern der Community verfeinert und kampferprobt',
  _HWORK6: 'Möchten Sie weitere Beispiele erfahren?'
};

export default translate;
