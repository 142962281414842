import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import * as Scroll from 'react-scroll';
import { useSelector } from 'react-redux';

// import Button from '../../components/Button';
import Section from '../../components/Section';
import Button from '../../components/Button';
import styles from './emobility.module.scss';

import emobilityImage from '../../assets/img/emobility/be-energised.png';
import chargerImage from '../../assets/img/emobility/charger.svg';

import metaValues from '../../../config';

const EMobilityScreen = () => {
  const { defaultTitle } = metaValues;
  const { language } = useSelector(({ common }: any) => common);

  return (
    <div>
      <Helmet>
        <title>E-Mobility solutions - {defaultTitle}</title>
        <meta
          name="description"
          content="There you can find our E-Mobility expertise section"
        />
        <meta
          property="keywords"
          content="react emobility solutions, react ocpp demo, react ocpp management system, ocpp dashboard, ocpp billing system, ocpp charging management"
        />
        <meta property="og:title" content={defaultTitle} />
        <meta property="og:image" content="/assets/svg/monitor-window.svg" />
      </Helmet>

      <div className={styles.background}>
        <div className={styles.content}>
          <div className={styles.title}>
            <h1>E-Mobility solutions</h1>
            <p>
              Efficient solutions for both sides of the e-mobility industry -
              EMSP and CPO operators. Guaranteed seamless experience through
              Open Standards implementation, apps &amp; platforms development,
              domain consulting and many more.
            </p>
          </div>
        </div>
      </div>

      <Section className={styles.content}>
        <Link
          to={`/${language}/expertise`}
          onClick={() => Scroll.animateScroll.scrollToTop({ duration: 100 })}
        >
          Back to expertise section
        </Link>
        <h1>Products of the future for the next generation</h1>
        <img className={styles.eImage} src={emobilityImage} alt="EV Charging" />
        <p>
          Through software, we bring the industry most flexible EV
          infrastructure software solutions. Manage any OCPP charger, charge any
          electric car, and accept every major payment method.
        </p>
        <p>
          Our innovative products are shaping the future of automotive design.
          We base complex automotive designs on forward-looking solutions. As a
          long-standing and reliable partner to the automotive industry,
          voestalpine manufactures innovative products from design to prototype
          and from toolmaking to serial production. Each component, whether an
          individual part or a complex module, meets the necessary safety and
          weight reduction requirements. This way, each innovative component
          makes a contribution to a brighter future.
        </p>

        <h1>Let&apos;s get started</h1>
        <img
          className={styles.eImage}
          src={chargerImage}
          alt="EV Charge management system"
        />

        <p>
          EV charging doesn&apos;t have to be complicated. We can bring your
          end-to-end EV infrastructure solution with a lot of custom settings
          and flexible pricing for your customers.
        </p>
        <Link
          to={`/${language}/expertise/ev-charging`}
          onClick={() => Scroll.animateScroll.scrollToTop({ duration: 100 })}
        >
          <Button icon="Menu" size="large">
            More about EV charging
          </Button>
        </Link>

        <h3>EV charger management</h3>
        <p>
          Connect & control any OCPP EV charger. You can build own software
          plans starting at $0. Free hours, discounts on different timeranges
          allows you to add gamification and marketing features to yours
          stations (support OCCP protocols and most famous EV-charging
          manufacturers).
        </p>

        <h3>EV management integrations with CRM</h3>
        <p>
          We can easy integrate our management systems for chargers to your CRM
          system, notification services and implement any complexity integration
          with third-parties software.
        </p>

        <h3>EV driver account</h3>
        <p>No membership fees or wallet deposits required, now or ever.</p>
      </Section>
    </div>
  );
};

export default EMobilityScreen;
