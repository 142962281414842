import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useParams } from 'react-router-dom';
import * as Scroll from 'react-scroll';
import { useSelector } from 'react-redux';

// import Button from '../../components/Button';
import Section from '../../components/Section';
import CardForm from '../../components/CreditCard';
import DonutChart from '../../components/DonutChart';
import Receipt from '../../components/Receipt';
import BuyList from '../../components/BuyList';
import Charging from '../../components/Charging';
import styles from './charts.module.scss';

import metaValues from '../../../config';
import Button from '../../components/Button';

const ChartsScreen = () => {
  const { id } = useParams();
  const { defaultTitle } = metaValues;
  const [current, setCurrent] = useState(id || 'chart-example');
  const { language } = useSelector(({ common }: any) => common);

  return (
    <div>
      <Helmet>
        <title>React charts dashboards - {defaultTitle}</title>
        <meta
          name="description"
          content="There you can find our portfolio about charts implementations"
        />
        <meta
          property="keywords"
          content="react emobility solutions, react ocpp demo, react ocpp management system, ocpp dashboard, ocpp billing system, ocpp charging management"
        />
        <meta property="og:title" content={defaultTitle} />
        <meta property="og:image" content="/assets/svg/monitor-window.svg" />
      </Helmet>

      <Section className={styles.content}>
        <Link
          to={`/${language}/expertise`}
          onClick={() => Scroll.animateScroll.scrollToTop({ duration: 100 })}
        >
          Back to expertise section
        </Link>
        <h1>Dynamic and interactive components for any needs</h1>
        <p>
          We can create reusable and beauty components for any needs on your
          project
        </p>

        <div className={styles.grid}>
          <div className={styles.left}>
            <Link
              to={`/${language}/react-components/chart-example`}
              onClick={() => setCurrent('chart-example')}
            >
              <Button>Charts</Button>
            </Link>
            <Link
              to={`/${language}/react-components/payment-form`}
              onClick={() => setCurrent('payment-form')}
            >
              <Button>Card</Button>
            </Link>
            <Link
              to={`/${language}/react-components/receipt-example`}
              onClick={() => setCurrent('receipt-example')}
            >
              <Button>Receipt</Button>
            </Link>
            <Link
              to={`/${language}/react-components/buylist-example`}
              onClick={() => setCurrent('buylist-example')}
            >
              <Button>Buy list</Button>
            </Link>
            <Link
              to={`/${language}/react-components/charging-screen-example`}
              onClick={() => setCurrent('charging-screen-example')}
            >
              <Button>Charging</Button>
            </Link>
          </div>

          <div className={styles.right}>
            {current === 'chart-example' && (
              <DonutChart value={25} size={305} strokeWidth={60} />
            )}

            {current === 'payment-form' && (
              <CardForm
                onSubmit={values => alert(JSON.stringify(values, null, 2))}
              />
            )}

            {current === 'receipt-example' && <Receipt />}
            {current === 'buylist-example' && <BuyList />}
            {current === 'charging-screen-example' && <Charging />}
          </div>
        </div>
      </Section>
    </div>
  );
};

export default ChartsScreen;
