// @flow

import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import _ from 'lodash';
// import {
//   JsonView,
//   allExpanded,
//   darkStyles,
//   defaultStyles
// } from 'react-json-view-lite';
// import 'react-json-view-lite/dist/index.css';
// import convert from 'xml-js';

import Button from '../../components/Button';
import Input from '../../components/Input';
import Section from '../../components/Section';
import styles from './poc.module.scss';

import mapConfig from './config';

// $FlowFixMe
import 'react-responsive-modal/styles.css';

import metaValues from '../../../config';

const renderItemMappings = ({ itemMappings }: any): any => (
  <div className={styles.table3columns}>
    <ul>
      {_.map(itemMappings, (item, index) => (
        <li>
          <div>{index + 1}</div>
          <div className={styles.fields}>
            <div className={styles.title}>Target field name:</div>
            <div>
              <Input
                fullWidth
                type="text"
                value={item.field}
                placeholder="stream URL"
              />
            </div>

            <div className={styles.title}>Source field address:</div>
            <div>
              <Input
                fullWidth
                type="text"
                value={item.source}
                placeholder="stream URL"
              />
            </div>
          </div>

          <div className={styles.actions}>
            <Button size="small">Delete</Button>
          </div>
        </li>
      ))}
    </ul>
  </div>
);

const POCScreen = (): any => {
  const { defaultTitle } = metaValues;
  const [fileData, setFileData] = React.useState('');
  const [json, setJSON] = React.useState();

  const handleFileChosen = (file: any) => {
    const fileReader = new FileReader();
    fileReader.onloadend = () => {
      // console.log(fileReader.result);
      const jsonObject = convert.xml2json(fileReader.result, {
        compact: false,
        spaces: 4
      });
      setJSON(JSON.parse(jsonObject));
      setFileData(String(fileReader.result));
    };

    fileReader.readAsText(file);
  };

  return (
    <section>
      <Helmet>
        <title>POC Page - {defaultTitle}</title>
        <meta
          name="description"
          content="We are provides React.js development services to help companies and startups create their dream projects. We always look for the best solutions for our clients businesses. One of these solutions is to use React.js for online services and web applications development."
        />
        <meta
          property="keywords"
          content="React application development, Nodejs development"
        />
        <meta property="og:title" content={defaultTitle} />
        <meta property="og:image" content="/assets/img/productivity.svg" />
      </Helmet>

      <Section>
        <Link to="/">Back to home</Link>
        <div className={styles.content}>
          <h1>POC Example</h1>

          <div className={styles.fileArea}>
            <center>
              <h3>Upload file:</h3>
              <input
                type="file"
                id="file"
                className="input-file"
                accept=".csv,.xml,.json"
                onChange={e => handleFileChosen(e.target.files[0])}
              />
            </center>
          </div>

          {/*
          fileData && (
            <div className={styles.jsonView}>
              <JsonView
                data={json}
                shouldExpandNode={allExpanded}
                style={darkStyles}
              />
            </div>
          )
          */}

          {fileData && (
            <div className={styles.grid}>
              <div>
                <h3>Streams:</h3>
                <ul className={styles.feedList}>
                  <li className={styles.active}>My5</li>
                  <li>AMAGI</li>
                  <Button size="small">Add new</Button>
                </ul>
              </div>
              <div>
                <div className={styles.configArea}>
                  <Button className={styles.button}>LIVE PREVIEW</Button>

                  <h2>Configuration for mapping</h2>
                  <div className={styles.table}>
                    <div>Input source:</div>
                    <div>
                      <Input
                        fullWidth
                        type="text"
                        name="inputSource"
                        value="http://www.my5.tv/dvbeitsample.xml"
                        placeholder="stream URL"
                      />
                    </div>
                    <div>Array path:</div>
                    <div>
                      <Input
                        fullWidth
                        type="text"
                        name="inputSource"
                        placeholder="array source"
                        value={mapConfig.listSource}
                      />
                    </div>
                  </div>
                  <br />
                  <Button className={styles.button} size="medium">
                    Add
                  </Button>
                  <h3>Item parsing properties:</h3>
                  {renderItemMappings(mapConfig)}

                  <Button size="medium">Save</Button>
                </div>
              </div>
            </div>
          )}
        </div>
      </Section>
    </section>
  );
};

export default POCScreen;
