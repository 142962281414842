import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import * as Scroll from 'react-scroll';
import { useSelector } from 'react-redux';

// import Button from '../../components/Button';
import Section from '../../components/Section';
import Button from '../../components/Button';
import styles from './dashboard.module.scss';
import metaValues from '../../../config';
import reactImage from '../../assets/svg/reactjs.svg';
import reactIllustration from '../../assets/img/react-application.png';

const ReactDashboards = () => {
  const { defaultTitle } = metaValues;
  const { language } = useSelector(({ common }: any) => common);

  return (
    <div className={styles.body}>
      <Helmet>
        <title>Dashboard development (React) - {defaultTitle}</title>
        <meta
          name="description"
          content="Maximize the profitability of your charging network and experience our state-of-the-art EV charging station software for operators and service providers."
        />
        <meta property="og:title" content={defaultTitle} />
        <meta property="og:image" content="/assets/svg/monitor-window.svg" />
      </Helmet>

      <div className={styles.background}>
        <div className={styles.context}>
          <div className={styles.title}>
            <h1>React Development Services</h1>
            <p>
              Want a dynamic and interactive single page application to be
              built? Look no further! We provide ReactJS Development Services to
              cater to all your development needs. The ReactJS applications we
              create are highly functional and gives a native feeling to your
              mobile apps. Needless to say, they are lightweight, quick and
              responsive.
            </p>
          </div>
        </div>
      </div>

      <Section className={styles.content}>
        <Link to={`/${language}/expertise`}>Back to expertise section</Link>
        <h1>Enterprise solutions. Peak performance. Code quality.</h1>

        <img
          src={reactIllustration}
          className={styles.reactImage}
          alt="React applications development"
        />

        <p>
          We have a dedicated team of React developers who leverage the
          flexibility and dynamism offered by React JS to develop
          consumer-facing web applications with the highest levels of security.
          Our React Development Services help in developing data-driven web
          applications with great speed, simplicity and robustness.
        </p>
        <p>
          React JS is the primary front-end platform on which we build user
          interfaces and UI components. All our teams have extensive knowledge
          and experience with React development and have worked on dozens of
          projects.
        </p>
        <p>
          Access only experienced React.js developers with a track record in
          large-scale web development. Quality standards defined by +200
          projects.
        </p>

        <img
          src={reactImage}
          className={styles.reactImage}
          alt="React applications development"
        />

        <Link
          to={`/${language}/react-components`}
          onClick={() => Scroll.animateScroll.scrollToTop({ duration: 100 })}
        >
          <Button>Show me examples</Button>
        </Link>

        <h3>Start Tomorrow</h3>
        <p>
          You don&apos;t need to build your internal organisation. Hire our team
          of developers for the project and replace us with internal staff
          later.
        </p>

        <h3>React.js development from scratch</h3>
        <p>
          You can outsource React development to us, and we&apos;ll build a
          project from scratch for you.
        </p>

        <h3>Migration to React</h3>
        <p>
          Our experts will perform high-quality migration to React from any
          other frontend framework.
        </p>

        <h3>Dedicated React.js development team</h3>
        <p>
          Hire dedicated React.js developers to fit your needs at scale. They
          are also skilled enough to refactor the poorly written code by another
          React.js development company to meet industry standards.
        </p>

        <h3>React Native app development</h3>
        <p>
          Experience the full power of the top cross-platform mobile app
          development framework.
        </p>
      </Section>
    </div>
  );
};

export default ReactDashboards;
