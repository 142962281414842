// @flow

import _ from 'lodash';
import moment from 'dayjs';
import React, { useState } from 'react';
import useOnclickOutside from 'react-cool-onclickoutside';

import styles from './date-picker.module.scss';

type Props = {
  onChange: (data: Object) => void,
  onClickOutside: () => void,
  isVisible?: boolean,
  maxYear?: number,
  selectedMonth?: number,
  selectedYear?: number
};

const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
];

// eslint-disable-next-line react/prefer-exact-props
const MonthYearPicker = (props: Props): any => {
  const {
    onClickOutside,
    isVisible,
    maxYear,
    selectedMonth,
    selectedYear,
    onChange
  } = props;

  const ref = useOnclickOutside(() => {
    if (onClickOutside) {
      onClickOutside();
    }
  });

  const [year, setYear] = useState(Number(moment().format('YYYY')));
  const [, setMonth] = useState(Number(moment().format('MM')));

  const yearChange = (type: boolean) => {
    const nextValue = year + (type ? 1 : -1);

    if (nextValue <= Number(maxYear) && nextValue >= Number(selectedYear)) {
      setYear(nextValue);
    }
  };

  const renderYearMonths = () => {
    const currentYear = Number(moment().format('YYYY'));

    return _.map(months, (mnth, index) => {
      const isCurrent = Number(index) + 1 === Number(selectedMonth);
      const isActive =
        (Number(index + 1) >= Number(selectedMonth) &&
          Number(year) === Number(currentYear)) ||
        Number(year) > Number(currentYear);

      const style =
        isCurrent && Number(year) === Number(currentYear)
          ? styles.activeMonth
          : styles.oneMonth;

      const item = isActive ? (
        <div
          className={styles.style}
          onClick={() => {
            setMonth(index + 1);
            if (onChange) {
              onChange({ year, month: index + 1 });
            }

            if (onClickOutside) {
              onClickOutside();
            }
          }}
        >
          {mnth}
        </div>
      ) : (
        <div className={styles.unActiveMonth}>{mnth}</div>
      );

      return (
        <div key={mnth} className={style}>
          {item}
        </div>
      );
    });
  };

  if (isVisible) {
    return (
      <div className={styles.monthYearPicker} ref={ref}>
        <center className={styles.padding}>Please select month and year</center>
        <center>
          <div
            className={styles.decreaseYear}
            onClick={() => yearChange(false)}
          >
            {/* <Icon.MinusButton /> */}
          </div>
          <div className={styles.yearMain}>{year}</div>
          <div className={styles.increaseYear} onClick={() => yearChange(true)}>
            {/* <Icon.AddButton /> */}
          </div>
        </center>
        <div className={styles.grid}>{renderYearMonths()}</div>
      </div>
    );
  }

  return null;
};

// $FlowFixMe
const maxYear = Number(moment().format('YYYY')) + 5;
// $FlowFixMe
const selectedMonth = Number(moment().format('MM'));
// $FlowFixMe
const selectedYear = Number(moment().format('YYYY'));

MonthYearPicker.defaultProps = {
  isVisible: false,
  maxYear,
  selectedMonth,
  selectedYear
};

export default MonthYearPicker;
