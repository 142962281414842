import React, { useState } from 'react';

import DonutChart from '../DonutChart';
import { Type2, Type1, CCS, Chademo } from '../TypeIcons';
import Button from '../Button';
import styles from './charging.module.scss';

const stations = [
  {
    id: 1,
    title: 'Superior Golf & Spa Resort',
    description:
      '<p>When you want to escape from the city. When you want to experience a world of comfort and beauty. When you want more.</p><p>The resort is located only 15 minutes away from the city centre and looks forward to meeting the guests at 1A Akademika Kurchatova Prospeкt, 61108 Kharkiv, Ukraine.</p>',
    address: 'Kharkiv, 1A, Akademika Kurchatova Avenue',
    phone: '+38 (044) 492-97-27',
    connectors: [
      {
        id: 1,
        maxPower: 11,
        chargingType: 'Free',
        connectorType: 'Type2'
      },
      {
        id: 2,
        maxPower: 22,
        chargingType: 'Free for clients',
        connectorType: 'Type2'
      },
      {
        id: 3,
        maxPower: 120,
        occupied: true,
        percent: 39,
        currentPower: 119,
        chargedKw: 56.35,
        currentVoltage: 403,
        currentAmp: 392,
        connectorType: 'CCS',
        timeSpendFull: '00:52:12',
        timeSpend: '52 minutes',
        prices: '9UAH/kW'
      }
    ]
  },
  {
    id: 2,
    title: 'IONITY AC/DC by UGV Bi3c-Hp RedCube',
    description:
      '<p>When you want to escape from the city. When you want to experience a world of comfort and beauty. When you want more.</p><p>The resort is located only 15 minutes away from the city centre and looks forward to meeting the guests at 1A Akademika Kurchatova Prospeкt, 61108 Kharkiv, Ukraine.</p>',
    address: 'Kharkiv, Nauki Avenue, 9',
    phone: '+38 (095) 200-7-002',
    connectors: [
      {
        id: 1,
        maxPower: 7,
        chargingType: 'Free',
        connectorType: 'Type1'
      },
      {
        id: 2,
        maxPower: 7,
        chargingType: 'Free',
        connectorType: 'Type1'
      },
      {
        id: 3,
        maxPower: 50,
        occupied: true,
        percent: 78,
        currentPower: 43,
        chargedKw: 25.35,
        currentVoltage: 376,
        currentAmp: 121,
        connectorType: 'Chademo',
        timeSpendFull: '01:26:32',
        timeSpend: '1 hr 26 mins',
        prices: '7UAH/kW'
      }
    ]
  }
];

const getConnector = type => {
  switch (type) {
    case 'Type2':
      return Type2;
    case 'Type1':
      return Type1;
    case 'CCS':
      return CCS;
    default:
      return Chademo;
  }
};

const renderFreeConnector = chargeInfo => {
  const Connector = getConnector(chargeInfo.connectorType);

  return (
    <div className={styles.charging}>
      <h3>Superior Golf & Spa Resort</h3>
      <div className={styles.address}>
        Kharkiv, 1A, Akademika Kurchatova Avenue
      </div>

      <DonutChart
        value={0}
        isChartOnly
        strokeColor="green"
        strokeWidth={40}
        size={380}
      />
      <div className={styles.inside}>
        <div className={styles.free}>Free</div>
        <div className={styles.freePower}>
          <span>{chargeInfo.maxPower}</span>kW/h
        </div>
        <div className={styles.freeConnector}>
          <Connector color="green" />
          <Button size="large" className={styles.freeStart}>
            Start
          </Button>
        </div>
      </div>

      <ul className={styles.stats}>
        <li>
          <div className={styles.title}>Balance</div>
          <div className={styles.money}>
            <span>637.52</span>UAH
          </div>
        </li>
      </ul>
    </div>
  );
};

const renderOccupiedConnector = chargeInfo => {
  const Connector = getConnector(chargeInfo.connectorType);

  return (
    <div className={styles.charging}>
      <h3>Superior Golf & Spa Resort</h3>
      <div className={styles.address}>
        Kharkiv, 1A, Akademika Kurchatova Avenue
      </div>

      <DonutChart
        value={chargeInfo.percent}
        isChartOnly
        strokeColor="green"
        strokeWidth={40}
        size={380}
      />
      <div className={styles.inside}>
        <div className={styles.percent}>
          {chargeInfo.percent}
          <span>%</span>
        </div>
        <div className={styles.info}>
          {chargeInfo.currentAmp}A
          <span>
            {chargeInfo.currentPower}
            <i>kW/h</i>
          </span>
          {chargeInfo.currentVoltage}V
        </div>
        <div className={styles.time}>
          <div className={styles.connector}>
            <Connector color="#ccc" className={styles.connectorIcon} />
          </div>
          <div>
            <h3>
              {chargeInfo.connectorType} {chargeInfo.maxPower}kWt
            </h3>
            {chargeInfo.timeSpendFull}
          </div>
        </div>
        <div className={styles.timeSmall}>{chargeInfo.timeSpend}</div>
      </div>

      <ul className={styles.stats}>
        <li key={1}>
          <div className={styles.title}>Charged</div>
          <div className={styles.power}>
            <span>{chargeInfo.chargedKw}</span>kW
          </div>
        </li>
        <li key={2}>
          <div className={styles.title}>Balance spend</div>
          <div className={styles.money}>
            <span>117.71</span>UAH
          </div>
        </li>
        <li key={3}>
          <div className={styles.title}>Balance left</div>
          <div className={styles.money}>
            <span>637.52</span>UAH
          </div>
        </li>
      </ul>
    </div>
  );
};

const ChargeScreen = () => {
  const [station, setStation] = useState();
  const [connector, setConnector] = useState();
  let connectors = [];

  const stationInfo = stations.find(x => x.id === station);

  if (stationInfo) {
    connectors = stationInfo.connectors;
  }

  const chargeInfo = connectors.find(x => x.id === connector);

  return (
    <div>
      {station && (
        <div
          className={styles.back}
          onClick={() => {
            setStation(false);
            setConnector(false);
          }}
        >
          Back to stations list
        </div>
      )}

      {!station && (
        <div>
          <h2>Station list</h2>
          <ul className={styles.stationList}>
            {stations.map(item => {
              const { id, title, address } = item;
              return (
                <li key={id}>
                  <h3 onClick={() => setStation(id)}>{title}</h3>
                  <div>{address}</div>
                </li>
              );
            })}
          </ul>
        </div>
      )}

      {station && connector && (
        <div className={styles.back} onClick={() => setConnector(false)}>
          Back to station
        </div>
      )}

      {station && !connector && (
        <div className={styles.main}>
          <h2>{stationInfo.title}</h2>
          <ul>
            <li>{stationInfo.address}</li>
            <li>{stationInfo.phone}</li>
          </ul>

          {stationInfo.description && (
            <div
              dangerouslySetInnerHTML={{ __html: stationInfo.description }}
            />
          )}

          <ul className={styles.connList}>
            {connectors.map(item => {
              const {
                id,
                maxPower,
                chargingType,
                connectorType,
                occupied,
                prices
              } = item;
              const color = occupied ? '#00bafe' : 'green';
              const style = occupied ? styles.occupied : '';
              // const { Type2 } = ConnTypes;
              const Connector = getConnector(
                chargeInfo ? chargeInfo.connectorType : false
              );

              return (
                <li className={style} key={id} onClick={() => setConnector(id)}>
                  <div className={styles.first}>
                    {occupied ? <div>Charging</div> : <div>Max. power</div>}
                    <div className={styles.power}>
                      <span>{maxPower}</span>kW/h
                    </div>
                    <div className={styles.price}>{chargingType || prices}</div>
                  </div>
                  <div className={styles.second}>
                    <div>{connectorType}</div>
                    <Connector color={color} className={styles.connIcon} />
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      )}
      {connector && chargeInfo.occupied && renderOccupiedConnector(chargeInfo)}
      {connector && !chargeInfo.occupied && renderFreeConnector(chargeInfo)}
    </div>
  );
};

export default ChargeScreen;
