import React from 'react';

import styles from './robot.module.scss';

const renderRobot = () => (
  <div className={styles.artboard}>
    <div className={styles.bb8}>
      <div className={styles.bb8body}>
        <div className={`${styles.dot} ${styles['dot-1']}`}>
          <div className={`${styles.line} ${styles['line-1']}`} />
          <div className={`${styles.line} ${styles['line-2']}`} />
          <div className={`${styles.line} ${styles['line-3']}`} />
        </div>
        <div className={`${styles.dot} ${styles['dot-2']}`} />
        <div className={`${styles.circle} ${styles['circle-1']}`} />
        <div className={`${styles.circle} ${styles['circle-2']}`} />
        <div className={`${styles.circle} ${styles['circle-3']}`} />
      </div>
      <div className={styles['body-shadow-crop']}>
        <div className={styles['body-shadow']} />
      </div>
      <div className={styles['bb8-head']}>
        <div className={styles['head-bottom']}>
          <div className={styles['head-side-1']} />
          <div className={styles['head-side-2']} />
          <div className={styles['head-bottom-base']} />
        </div>
        <div className={styles['head-top-crop']}>
          <div className={styles['head-top']} />
        </div>
        <div className={styles.lens} />
        <div className={styles.freckle} />
      </div>
      <div className={styles.speedlines}>
        <div className={`${styles.one} ${styles.tail}`} />
        <div className={`${styles.two} ${styles.tail}`} />
        <div className={`${styles.three}`} />
        <div className={`${styles.four}`} />
        <div className={`${styles.five} ${styles.tail}`} />
      </div>
      <div className={styles.sparkles}>
        <div className={`${styles.one} ${styles.small} ${styles['pulse-1']}`} />
        <div
          className={`${styles.two} ${styles.blue} ${styles.small} ${
            styles['pulse-2']
          }`}
        />
        <div
          className={`${styles.three} ${styles.blue} ${styles.med} ${
            styles['pulse-3']
          }`}
        />
        <div
          className={`${styles.four} ${styles.orange} ${styles['pulse-2']}`}
        />
        <div
          className={`${styles.five} ${styles.orange} ${styles['pulse-1']}`}
        />
        <div
          className={`${styles.six} ${styles.blue} ${styles.small} ${
            styles.pulse
          }`}
        />
        <div
          className={`${styles.seven} ${styles.blue} ${styles.small} ${
            styles.pulse
          }`}
        />
        <div
          className={`${styles.eight} ${styles.small} ${styles['pulse-3']}`}
        />
        <div className={`${styles.nine} ${styles.pulse}`} />
        <div className={`${styles.nine} ${styles.pulse}`} />
        <div
          className={`${styles.ten} ${styles.orange} ${styles['small-1']} ${
            styles.pulse
          }`}
        />
        <div className={`${styles.eleven} ${styles.small} ${styles.pulse}`} />
        <div
          className={`${styles.twelve} ${styles.small} ${styles['pulse-2']}`}
        />
        <div
          className={`${styles.thirteen} ${styles.orange} ${styles.small} ${
            styles.pulse
          }`}
        />
        <div
          className={`${styles.fourteen} ${styles.orange} ${styles.med} ${
            styles['pulse-3']
          }`}
        />
        <div
          className={`${styles.fifteen} ${styles.small} ${styles['pulse-1']}`}
        />
        <div className={`${styles.sixteen} ${styles.small} ${styles.pulse}`} />
      </div>
      <div className={styles.ground}>
        <div className={styles.one}>
          <div className={`${styles.bump} ${styles['move-1']}`} />
        </div>
        <div className={styles.two} />
        <div className={styles.three}>
          <div className={`${styles.bump} ${styles['move-2']}`} />
        </div>
        <div className={styles.four}>
          <div className={styles.bump} />
        </div>
        <div className={styles.five} />
        <div className={styles.six}>
          <div className={`${styles.bump} ${styles['move-2']}`} />
        </div>
        <div className={styles.seven}>
          <div className={styles.bump} />
        </div>
        <div className={styles.eight}>
          <div className={`${styles.bump} ${styles['move-1']}`} />
        </div>
      </div>
    </div>
  </div>
);

export default renderRobot;
