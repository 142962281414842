import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

// import Button from '../../components/Button';
import Section from '../../components/Section';
import styles from './ev-charging.module.scss';
import chargeStation from '../../assets/img/expertise/ev-charger-ultra.png';
import metaValues from '../../../config';

const EVCharging = () => {
  const { defaultTitle } = metaValues;

  return (
    <div className={styles.body}>
      <Helmet>
        <title>EV charging software (OCPP) - {defaultTitle}</title>
        <meta
          name="description"
          content="Maximize the profitability of your charging network and experience our state-of-the-art EV charging station software for operators and service providers."
        />
        <meta property="og:title" content={defaultTitle} />
        <meta property="og:image" content="/assets/svg/monitor-window.svg" />
      </Helmet>

      <div className={styles.evbackground}>
        <div className={styles.evcontent}>
          <div className={styles.title}>
            <h1>Software for the EV charging industry</h1>
            <p>
              Use our white-label EV charging software to easily manage your EV
              charging infrastructure. All charge points are administered on a
              multi-level web portal with separated roles and responsibilities –
              you can decide who can access the system. We offer both outsourced
              as well as fully self-managed packages of our EV charging
              management software, fitting to your business needs.
            </p>
          </div>
        </div>
      </div>

      <Section className={styles.content}>
        <Link to="/expertise">Back to expertise section</Link>
        <h1>Charging management made easy</h1>
        <p>
          Charging management software lets you track, manage, and optimize
          electric vehicle charging and earn additional revenue. From accessing
          advanced insights via our Business Portal to managing charging
          on-the-go via our Charge app, going electric has never been easier.
        </p>

        <img
          src={chargeStation}
          className={styles.station}
          alt="EV Fast charge station"
        />

        <h1>Make optimal use of the grid with EV smart charging</h1>
        <p>
          If lots of electric vehicles would charge at the same time, on the
          same grid, there is a chance that the energy network will overload.
          With smart charging, you are able to increase the number of electric
          vehicles that can be charged simultaneously without making additional
          investments in the grid.
        </p>

        <h3>EV charger management</h3>
        <p>
          Connect & control any OCPP EV charger. You can build own software
          plans starting at $0. Free hours, discounts on different timeranges
          allows you to add gamification and marketing features to yours
          stations (support OCCP protocols and most famous EV-charging
          manufacturers).
        </p>

        <h3>Infrastructure Management</h3>
        <ul>
          <li>Manage EV charging stations, chargers, and connectors</li>
          <li>Monitor sessions, stations, and chargers</li>
          <li>Smart energy management with load balancing</li>
          <li>Troubleshoot problems</li>
          <li>Apply remote commands (reboot, firmware update, etc.)</li>
          <li>Dashboard with real-time data and Google maps</li>
          <li>
            Hardware flexibility through Open Charge Point Protocol – OCPP
          </li>
        </ul>

        <h3>API Platform Solution</h3>
        <p>
          Customize your EV charging experience with our innovative and open
          charging network platform of software and services. Our API Platform
          empowers operators to create their own user experience through a
          powerful API gateway that allows you to develop your own driver
          application and capitalize on management portal to manage your
          stations. Whether you want to integrate your current mobile app, tie
          charging into rewards or memberships, or have other eMobility
          services, our API Platform supports your needs. It’s also perfect for
          integration into fleet operations, energy management solutions and
          vehicle telematics systems.
        </p>
      </Section>
    </div>
  );
};

export default EVCharging;
