/* global require */

import * as React from 'react';
import moment from 'moment';

import styles from './receipt.module.scss';

import AcceptedIcon from '../../assets/svg/accepted.svg';

const mock = require('./mock-data');

const Receipt = () => {
  const { items } = mock;
  const total = items.reduce((a, b) => a + b.amount, 0);

  const [{ date: startDate }] = items.slice(0, 1);
  const [{ date: endDate }] = items.slice(-1);

  return (
    <div className={styles.content}>
      <section className={styles.section}>
        <div className={styles.acceptedIcon}>
          <img src={AcceptedIcon} alt="Accepted" />
        </div>
        <h1>Successfull!</h1>
        <p>Your transaction was successfull!</p>
        <hr />
        <h2>Customer Receipt</h2>

        <p>Your charging and top-up history</p>

        <div className={styles.grids}>
          <div>
            <b>Start period:</b>
            <i>
              {moment(startDate).format('D MMM YYYY')},{' '}
              {moment(startDate).format('HH:mm')}
            </i>
          </div>
          <div>
            <b>End period:</b>
            <i>
              {moment(endDate).format('D MMM YYYY')},{' '}
              {moment(endDate).format('HH:mm')}
            </i>
          </div>
        </div>

        <ul className={styles.grid}>
          {items.map(item => {
            const { amount, operation } = item;
            return (
              <li>
                <div>
                  <div>{operation}</div>
                  <div className={styles.location}>
                    567 SG Highway, New York
                  </div>
                </div>
                <div className={styles.amount}>{amount}</div>
              </li>
            );
          })}
          <li>
            <p>Total</p>
            <p>{Math.abs(total).toFixed(2)}</p>
          </li>
        </ul>
      </section>
    </div>
  );
};

export default Receipt;
