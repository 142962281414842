/* global module */

const mockItem = {
  totalAmount: 5615.75,
  totalSpend: 6450.9,
  totalIncome: 2150.45,
  items: [
    {
      date: new Date('2021/12/2, 11:40'),
      operation: 'Refund',
      amount: 75.15
    },
    {
      date: new Date('2021/12/29, 11:45'),
      operation: 'Charging',
      duration: 3800,
      amount: -92.25
    },
    {
      date: new Date('2021/12/30, 8:45'),
      operation: 'Charging',
      duration: 1800,
      amount: -42.25
    },
    {
      date: new Date('2021/12/31, 8:45'),
      operation: 'Charging',
      duration: 1900,
      amount: -45.05
    }
  ]
};

module.exports = mockItem;
