import moment from 'moment';

const convertDuration = duration => {
  // PT0H6M18S
  const [hours, minutes, seconds] = duration.split(/PT|H|M|S/).filter(Boolean);
  return moment.duration({ hours, minutes, seconds }).asSeconds();
};

const mapConfig = {
  listSource: 'DVBSchedule.Schedule.ScheduleEvent',
  itemMappings: [
    {
      field: 'scheduleDuration',
      source: 'schedDuration',
      addFunc: convertDuration
    },
    {
      field: 'billedDuration',
      source: 'billedDuration',
      addFunc: convertDuration
    },
    { field: 'eventId', source: 'eventId' },
    { field: 'billedStartTime', source: 'billedStartTime' },
    { field: 'scheduleStartTime', source: 'schedStartTime' },
    {
      field: 'episodeName',
      source:
        'DVBEITDescriptors.ExtendedEventDescriptor.Item.find(description=EpisodeName).text'
    },
    {
      field: 'episodeNumber',
      source:
        'DVBEITDescriptors.ExtendedEventDescriptor.Item.find(description=EpisodeNumber).text'
    },
    {
      field: 'seriesNumber',
      source:
        'DVBEITDescriptors.ExtendedEventDescriptor.Item.find(description=SeriesNumber).text'
    },
    {
      field: 'image',
      source: 'DVBEITDescriptors.FreesatMediaLocatorDescriptor.mediaUri'
    },

    {
      field: 'eventLanguage',
      source: 'DVBEITDescriptors.ShortEventDescriptor.language'
    },
    {
      field: 'eventName',
      source: 'DVBEITDescriptors.ShortEventDescriptor.eventName'
    },
    {
      field: 'eventDescription',
      source: 'DVBEITDescriptors.ShortEventDescriptor.text'
    },
    {
      field: 'eventLanguage',
      source: 'DVBEITDescriptors.ExtendedEventDescriptor.language'
    },

    {
      field: 'dataSpecifierId',
      source:
        'DVBEITDescriptors.PrivateDataSpecifierDescriptor.privateDataSpecifier'
    },

    // new name Image
    // { field: 'FreesatMediaLocatorDescriptor', source: 'DVBEITDescriptors.FreesatMediaLocatorDescriptor' },

    {
      field: 'contentDescriptor',
      source: 'DVBEITDescriptors.ContentDescriptor.level1'
    },

    {
      field: 'ComponentDescriptor',
      source: 'DVBEITDescriptors.ComponentDescriptor'
    },
    // { field: 'ShortEventDescriptor', source: 'DVBEITDescriptors.ShortEventDescriptor' },

    {
      field: 'ContentIdentifierDescriptor',
      source: 'DVBEITDescriptors.ContentIdentifierDescriptor'
    }
  ]
};

export default mapConfig;
