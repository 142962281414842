import React from 'react';
import styles from './donut.module.scss';

// https://codepen.io/zeroskillz/pen/mPmENy

const DonutChart = (props: any) => {
  const {
    className = '',
    isChartOnly = false,
    value = 59, // value the chart should show
    label = 'Completed', // label for the chart
    size = 150, // diameter of chart
    strokeWidth = 26, // width of chart line
    strokeColor = '#009688' // width of chart line
  } = props;

  const halfsize = size * 0.5;
  const radius = halfsize - strokeWidth * 0.5;
  const circumference = 2 * Math.PI * radius;
  const strokeval = (value * circumference) / 100;
  const dashval = `${strokeval} ${circumference}`;

  const trackstyle = { strokeWidth };
  const indicatorstyle = { strokeWidth, strokeDasharray: dashval };
  const rotateval = `rotate(-90 ${halfsize},${halfsize})`;

  return (
    <div className={`${styles.content} ${className}`.trim()}>
      <svg width={size} height={size}>
        <circle
          r={radius}
          cx={halfsize}
          cy={halfsize}
          transform={rotateval}
          style={trackstyle}
          className={styles.track}
        />
        <circle
          r={radius}
          cx={halfsize}
          cy={halfsize}
          transform={rotateval}
          style={indicatorstyle}
          className={styles.indicator}
          stroke={strokeColor}
        />
        {!isChartOnly && (
          <text
            className={styles.text}
            x={halfsize}
            y={halfsize + 8}
            style={{ textAnchor: 'middle' }}
          >
            <tspan className={styles.textVal}>{value}</tspan>
            <tspan className={styles.textPercent}>%</tspan>
            <tspan className={styles.textLabel} x={halfsize} y={halfsize + 22}>
              {label}
            </tspan>
          </text>
        )}
      </svg>
    </div>
  );
};

export default DonutChart;
