import * as React from 'react';
import axios from 'axios';
import { useState } from 'react';
import { Element } from 'react-scroll';

import Input from '../Input';
import Textarea from '../Textarea';
import Section from '../Section';
import Button from '../Button';

import styles from './write.module.scss';

const feedBack = () => {
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [message, setMessage] = useState();
  const [isError, setError] = useState();
  const [isSent, setSent] = useState();

  const handleSubmit = async () => {
    const result = await axios.post('/send', {
      name,
      email,
      message
    });

    if (result) {
      setSent(true);
    } else {
      setError(true);
    }
  };

  return (
    <Section className={styles.feedback}>
      <Element name="scrollToForm">
        <div className={styles.content}>
          <h1>HAVE ANY QUESTION ?</h1>
          <p>Send us an email with your questions</p>
          {!isSent && !isError && (
            <div className={styles.form}>
              <Input
                key="name"
                type="text"
                name="name"
                placeholder="Name"
                value={name}
                onChange={event => setName(event.target.value)}
              />
              <Input
                type="text"
                name="email"
                placeholder="Email"
                value={email}
                onChange={event => setEmail(event.target.value)}
              />
              <Textarea
                name="text"
                placeholder="Message"
                value={message}
                onChange={event => {
                  setMessage(event.target.value);
                }}
              />
              <Button size="large" onClick={handleSubmit}>
                Send
              </Button>
            </div>
          )}
          {isSent && <p>Your message was sent</p>}
          {isError && <p>Sorry, something went wrong! Try again please</p>}
        </div>
      </Element>
    </Section>
  );
};

export default feedBack;
